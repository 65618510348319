import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Typography, Row, Col } from 'antd';

import Avatar from 'components/Shared/User/Avatar';
import BookmarksList from 'components/Contacts/RelationCard/BookmarkList';

import Style from './styles';

const { Title, Text } = Typography;
const styleTitle = { margin: 0 };

const CallingModalLayout = (props) => {
    const {
        _room = '',
        transferToPhonenumber = false,
        avatarUri = '',
        callerName,
        callerPhoneNumber,
        description,
        body,
        actions
    } = props;

    
    return (
        <Row justify="center" wrap={false}>
            <Col>
                <Style>
                    <div className="call-incoming">
                        <div className="avatar-container">
                            <div className="avatar-animated-container">
                                <div className="bg-circle" />
                                <div className="bg-circle" />
                                <div className="bg-circle" />
                                <div className="bg-circle" />
                                <Avatar
                                    src={avatarUri}
                                    size="xxxl"
                                    alt={callerName}
                                    hasStatus={false}
                                />
                            </div>
                        </div>
                        <div className="text-container">
                            {callerPhoneNumber && (<Title level={4}>{callerPhoneNumber}</Title>)}
                            <Title
                                className="title"
                                style={styleTitle}
                                level={5}
                            >
                                {callerName}
                            </Title>
                            <Text className="desc">{description}</Text>
                        </div>
                        {body}
                        {actions && (
                            <div className="action-container">
                                {actions}
                            </div>
                        )}
                    </div>
                </Style>
            </Col>
            {transferToPhonenumber && (
                <Col>
                    <BookmarksList _room={_room} />
                </Col>
            )}
        </Row>
    );
}

CallingModalLayout.propTypes = {
    _room: PropTypes.string,
    transferToPhonenumber: PropTypes.bool,
    callerPhoneNumber: PropTypes.string,
    avatarUri: PropTypes.any,
    callerName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    body: PropTypes.any,
    actions: PropTypes.any
};

export default injectIntl(CallingModalLayout);
