import { connect } from 'react-redux';
import { selectors, actions, constants } from '@amplement/backend-connector';
import { getRoomFeatures } from 'selectors/room';
import IncommingCall from './index';

const mapStateToProps = (state, ownProps) => {
    const { avatarUri, title, fullname, displayablePhonenumber } = selectors.rooms.getRoomInitiatorByRoomIdSelector(state, ownProps?._room) || {};
    const members = selectors.rooms.getUniqRoomMembersWithoutMeOrCallerByRoomIdSelector(state, ownProps?._room) || {};
    const features = getRoomFeatures(state, ownProps?._room);

    return ({
        avatarUri,
        description: title,
        callerName: fullname,
        callerPhoneNumber: displayablePhonenumber,
        roomType: selectors.rooms.getRoomTypeByRoomIdSelector(state, ownProps?._room),
        members,
        transferToPhonenumber: features?.transferToPhonenumber,
        hasFastReply: !!features?.displayFastReply
    });
};

const mapDispatchToProps = dispatch => ({
    onAccept: (_room, kind = constants.rooms.ROOM_TYPE.VIDEO) =>
        dispatch(actions.rooms.acceptIncoming(_room, true, kind === constants.rooms.ROOM_TYPE.VIDEO, false)),
    onReject: (_room) => dispatch(actions.rooms.rejectIncoming(_room)),
    onIgnore: (_room) => dispatch(actions.rooms.ignoreIncoming(_room)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IncommingCall);
